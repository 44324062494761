import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import UpdatePage from './components/update_page';
import ConfirmPage from './components/confirm_page';

const KycRefreshView = ({
  contentLoading,
  accountDataMissing,
  personalDetailsUpdated,
  fetchPersonalDetails,
  unsetPersonalDetailsUpdated,
  dismissPersonalDetailsRefresh,
  isUpdating,
  setIsUpdating,
}) => {
  useEffect(() => {
    if (accountDataMissing || personalDetailsUpdated) {
      fetchPersonalDetails();
      unsetPersonalDetailsUpdated();
    }
  }, [accountDataMissing, personalDetailsUpdated]);

  if (contentLoading) return <Spinner wrapped={false} />;
  if (isUpdating) {
    return (
      <UpdatePage
        setIsUpdating={setIsUpdating}
        dismissPersonalDetailsRefresh={dismissPersonalDetailsRefresh}
      />
    );
  }
  return (
    <ConfirmPage
      setIsUpdating={setIsUpdating}
      dismissPersonalDetailsRefresh={dismissPersonalDetailsRefresh}
    />
  );
};

KycRefreshView.propTypes = {
  contentLoading: PropTypes.bool.isRequired,
  fetchPersonalDetails: PropTypes.func.isRequired,
  unsetPersonalDetailsUpdated: PropTypes.func.isRequired,
  accountDataMissing: PropTypes.bool.isRequired,
  personalDetailsUpdated: PropTypes.bool.isRequired,
  dismissPersonalDetailsRefresh: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  setIsUpdating: PropTypes.func.isRequired,
};

export default KycRefreshView;
