import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button, Spinner } from 'bv-components';
import { getValidationFunction } from 'validation-helpers';
import updatePersonalDetails from 'SharedComponents/personal_details/api/update_personal_details';
import EditAddressFields from 'SharedComponents/personal_details/components/edit_address/edit_address_fields';
import { PhoneNumber } from 'registration-common/components';
import Input from 'SharedComponents/personal_details/components/input';
import { t } from 'bv-i18n';
import SuccessSubmitModal from '../success_submit_modal';
import FailureSubmitModal from '../failure_submit_modal';

const FormView = ({
  setPersonalDetailsUpdated, countryZones, addressFields,
  onCancel, personalDetailsFields, dismissPersonalDetailsRefresh,
  phoneCountries, detailedPhoneNumber, canUpdatePhoneNumber,
}) => (
  <Form
    id="update-address-form"
    onSubmit={updatePersonalDetails}
  >
    {({
      submitError, pristine, submitting, submitSucceeded,
      hasValidationErrors, form, handleSubmit,
    }) => {
      if (submitting) return <Spinner wrapped={false} />;

      if (submitSucceeded) {
        return (
          <SuccessSubmitModal
            title={t('javascript.kyc_refresh.update.success.title')}
            message={t('javascript.kyc_refresh.update.success.message')}
            onClose={() => {
              setPersonalDetailsUpdated();
              dismissPersonalDetailsRefresh();
            }}
          />
        );
      }

      if (submitError) {
        return (
          <FailureSubmitModal
            title={t('javascript.kyc_refresh.error.title')}
            message={submitError}
            onClose={() => {
              const { values } = form.getState();
              form.reset();
              form.batch(() => {
                Object.entries(values).forEach(([key, value]) => {
                  form.change(key, value);
                });
              });
            }}
          />
        );
      }

      return (
        <form
          className="update-form"
        >
          {personalDetailsFields.map(({
            name, value, label, validations,
          }) => (
            <Field
              name={name}
              label={label}
              component={Input}
              initialValue={value}
              validate={getValidationFunction(validations)}
            />
          ))}
          <EditAddressFields
            addressFields={addressFields}
            countryZones={countryZones}
          />
          {canUpdatePhoneNumber && (
            <Field
              name="phone_number"
              component={PhoneNumber}
              label={t('javascript.kyc.callback.mobile_number')}
              countries={phoneCountries}
              initialValue={detailedPhoneNumber}
            />
          )}
          <div>
            <Button
              label={t('javascript.kyc_refresh.update.cancel_button')}
              secondary
              inverse
              disabled={submitting}
              onClick={onCancel}
            />
            <Button
              label={t('javascript.kyc_refresh.update.confirm_button')}
              secondary
              onClick={handleSubmit}
              disabled={pristine || hasValidationErrors || submitting}
            />
          </div>
        </form>
      );
    }}
  </Form>
);

FormView.propTypes = {
  personalDetailsFields: PropTypes.arrayOf(Object).isRequired,
  addressFields: PropTypes.arrayOf(Object).isRequired,
  setPersonalDetailsUpdated: PropTypes.func.isRequired,
  countryZones: PropTypes.arrayOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  dismissPersonalDetailsRefresh: PropTypes.func.isRequired,
  detailedPhoneNumber: PropTypes.instanceOf(Object).isRequired,
  phoneCountries: PropTypes.arrayOf(Object).isRequired,
  canUpdatePhoneNumber: PropTypes.bool.isRequired,
};

export default FormView;
