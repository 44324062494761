import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const SuccessSubmitModal = ({ onClose, message, title }) => (
  <RenderInBody>
    <Modal
      success
      actions={[{
        id: 'ok-btn',
        label: t('close'),
        primary: true,
        inverse: true,
        close: true,
        onClick: onClose,
      }]}
    >
      <h3 className="bvs-modal__title">{title}</h3>
      <p>{message}</p>
    </Modal>
  </RenderInBody>
);

SuccessSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default SuccessSubmitModal;
