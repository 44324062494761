import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'bv-i18n';
import { Button, SmallSpinner } from 'bv-components';
import {
  getPersonalDetailsFields,
  getAddressFields,
  getCountryZone,
  getDetailedPhoneNumber,
  getCanUpdatePhoneNumber,
  countryName as getCountryName,
} from 'SharedComponents/personal_details/ducks';
import confirmPersonalDetails from '../api/confirm_personal_details';
import SuccessSubmitModal from './success_submit_modal';
import FailureSubmitModal from './failure_submit_modal';

const ConfirmPage = ({
  addressFields, countryZone, setIsUpdating, personalDetailsFields,
  dismissPersonalDetailsRefresh, detailedPhoneNumber, canUpdatePhoneNumber,
}) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const displayPhoneNumber = () => detailedPhoneNumber.full_number || detailedPhoneNumber.number || '-';

  if (showSuccessModal) {
    return (
      <SuccessSubmitModal
        title={t('javascript.kyc_refresh.confirm.success.title')}
        onClose={dismissPersonalDetailsRefresh}
        message={t('javascript.kyc_refresh.confirm.success.message')}
      />
    );
  }

  if (showFailureModal) {
    return (
      <FailureSubmitModal
        title={t('javascript.kyc_refresh.error.title')}
        onClose={dismissPersonalDetailsRefresh}
        message={t('javascript.kyc_refresh.error.message')}
      />
    );
  }

  const onClickConfirm = () => {
    setIsSubmitting(true);
    confirmPersonalDetails()
      .then(({ success }) => {
        if (success) return setShowSuccessModal(true);
        return setShowFailureModal(true);
      })
      .catch(() => setShowFailureModal(true))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className="kyc-refresh">
      <h3 className="bvs-modal__title">
        {t('javascript.kyc_refresh.confirm.title')}
      </h3>
      <div className="kyc-refresh__content">
        <p className="bvs-p">
          {t('javascript.kyc_refresh.confirm.copy')}
        </p>
        <dl className="pd-list">
          {personalDetailsFields.map(({ value, name }) => (
            <div>
              <dt className="pd-list__label">{t(`javascript.personal_details.user_details.${name}`)}</dt>
              <dd className="pd-list__value">{(value || ' - ')}</dd>
            </div>
          ))}
          {addressFields.map(({ value, name, label }) => (
            <div>
              <dt className="pd-list__label">{label}</dt>
              <dd className="pd-list__value">{['county_id', 'state_id'].includes(name) ? countryZone : (value || ' - ')}</dd>
            </div>
          ))}
          {canUpdatePhoneNumber && (
            <div>
              <dt className="pd-list__label">{t('javascript.kyc.callback.mobile_number')}</dt>
              <dd className="pd-list__value">{displayPhoneNumber()}</dd>
            </div>
          )}
        </dl>
        <div className="pd-list__cta-wrapper">
          <Button
            label={t('javascript.kyc_refresh.confirm.update_button')}
            secondary
            inverse
            onClick={() => setIsUpdating(true)}
            disabled={isSubmitting}
          />
          <Button
            secondary
            onClick={onClickConfirm}
            disabled={isSubmitting}
          >
            {t('javascript.kyc_refresh.confirm.confirm_button')}
            {isSubmitting && <SmallSpinner />}
          </Button>
        </div>
      </div>
    </div>
  );
};

ConfirmPage.propTypes = {
  addressFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  personalDetailsFields: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  detailedPhoneNumber: PropTypes.instanceOf(Object),
  canUpdatePhoneNumber: PropTypes.bool,
  countryZone: PropTypes.string,
  setIsUpdating: PropTypes.func.isRequired,
  dismissPersonalDetailsRefresh: PropTypes.func.isRequired,
};

ConfirmPage.defaultProps = {
  addressFields: [],
  personalDetailsFields: [],
  detailedPhoneNumber: {},
  canUpdatePhoneNumber: false,
  countryZone: null,
};

const mapStateToProps = (state) => ({
  addressFields: getAddressFields(state),
  personalDetailsFields: getPersonalDetailsFields(state),
  detailedPhoneNumber: getDetailedPhoneNumber(state),
  canUpdatePhoneNumber: getCanUpdatePhoneNumber(state),
  countryName: getCountryName(state),
  countryZone: getCountryZone(state),
});

export default connect(mapStateToProps)(ConfirmPage);
