import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import { t } from 'bv-i18n';

const FailureSubmitModal = ({ onClose, message, title }) => (
  <RenderInBody>
    <Modal
      danger
      actions={[{
        id: 'ok-btn',
        label: t('close'),
        danger: true,
        close: true,
        onClick: onClose,
      }]}
    >
      <h3 className="bvs-modal__title">{title}</h3>
      <p className="bvs-text-error">{message}</p>
    </Modal>
  </RenderInBody>
);

FailureSubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default FailureSubmitModal;
