import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import Form from './form/form_redux';

const UpdatePage = ({
  setIsUpdating, dismissPersonalDetailsRefresh,
}) => {
  const onCancel = () => setIsUpdating(false);

  return (
    <div className="kyc-refresh">
      <h3 className="bvs-modal__title">
        {t('javascript.kyc_refresh.update.title')}
      </h3>
      <div className="kyc-refresh__update-form">
        <Form
          onCancel={onCancel}
          dismissPersonalDetailsRefresh={dismissPersonalDetailsRefresh}
        />
      </div>
    </div>
  );
};

UpdatePage.propTypes = {
  setIsUpdating: PropTypes.func.isRequired,
  dismissPersonalDetailsRefresh: PropTypes.func.isRequired,
};

export default UpdatePage;
