import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, isEmpty } from 'underscore';
import { Modal } from 'bv-components';
import {
  getLoadingPersonalDetails,
  fetchPersonalDetails as fetchPersonalDetailsThunk,
  unsetPersonalDetailsUpdated as unsetPersonalDetailsUpdatedAction,
} from 'SharedComponents/personal_details/ducks';

import KycRefreshView from './kyc_refresh_view';

const KycRefreshContainer = (props) => {
  const [enabled, setEnabled] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const dismissPersonalDetailsRefresh = () => setEnabled(false);

  if (!enabled) {
    return null;
  }

  return (
    <Modal
      icon
      info
      iconClosable
      onCloseClick={dismissPersonalDetailsRefresh}
      className="kyc-refresh-modal"
    >
      <KycRefreshView
        {...props}
        dismissPersonalDetailsRefresh={dismissPersonalDetailsRefresh}
        isUpdating={isUpdating}
        setIsUpdating={setIsUpdating}
      />
    </Modal>
  );
};

KycRefreshContainer.propTypes = {
  contentLoading: PropTypes.bool.isRequired,
  fetchPersonalDetails: PropTypes.func.isRequired,
  unsetPersonalDetailsUpdated: PropTypes.func.isRequired,
  accountDataMissing: PropTypes.bool.isRequired,
  personalDetailsUpdated: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  contentLoading: getLoadingPersonalDetails(state),
  accountDataMissing: isEmpty(state.personalDetails.accountData),
  occupationAndSalaryMissing: isEmpty(state.occupationAndSalary),
  personalDetailsUpdated: state.personalDetails.personalDetailsUpdated,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPersonalDetails: compose(dispatch, fetchPersonalDetailsThunk),
  unsetPersonalDetailsUpdated: compose(dispatch, unsetPersonalDetailsUpdatedAction),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(KycRefreshContainer);
