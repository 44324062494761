import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Spinner } from 'bv-components';
import FormView from './form_view';

const FormContainer = ({
  addressFields, setPersonalDetailsUpdated, countryZones, detailedPhoneNumber,
  onCancel, personalDetailsFields, dismissPersonalDetailsRefresh,
  phoneNumberCountries, fetchMobileNumberCountries, loadingMobileCountries,
  canUpdatePhoneNumber,
}) => {
  useEffect(() => {
    if (canUpdatePhoneNumber) fetchMobileNumberCountries();
  }, [canUpdatePhoneNumber]);

  if (
    (isEmpty(addressFields) && isEmpty(personalDetailsFields))
    || loadingMobileCountries
    || (canUpdatePhoneNumber && !phoneNumberCountries.length)
  ) return <Spinner wrapped={false} />;

  return (
    <FormView
      personalDetailsFields={personalDetailsFields}
      addressFields={addressFields}
      setPersonalDetailsUpdated={setPersonalDetailsUpdated}
      countryZones={countryZones}
      dismissPersonalDetailsRefresh={dismissPersonalDetailsRefresh}
      onCancel={onCancel}
      phoneCountries={phoneNumberCountries}
      detailedPhoneNumber={detailedPhoneNumber}
      canUpdatePhoneNumber={canUpdatePhoneNumber}
    />
  );
};

FormContainer.propTypes = {
  addressFields: PropTypes.arrayOf(Object).isRequired,
  personalDetailsFields: PropTypes.arrayOf(Object).isRequired,
  setPersonalDetailsUpdated: PropTypes.func.isRequired,
  countryZones: PropTypes.arrayOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  dismissPersonalDetailsRefresh: PropTypes.func.isRequired,
  phoneNumberCountries: PropTypes.arrayOf(Object).isRequired,
  fetchMobileNumberCountries: PropTypes.func.isRequired,
  loadingMobileCountries: PropTypes.bool.isRequired,
  detailedPhoneNumber: PropTypes.instanceOf(Object).isRequired,
  canUpdatePhoneNumber: PropTypes.bool.isRequired,
};

export default FormContainer;
