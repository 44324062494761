import { connect } from 'react-redux';
import { compose } from 'underscore';
import {
  getPersonalDetailsFields,
  getAddressFields,
  getCountryZones,
  setPersonalDetailsUpdated,
  getDetailedPhoneNumber,
  getCanUpdatePhoneNumber,
  fetchMobileNumberCountries,
  getMobileNumberCountries,
  getLoadingMobileCountries,
} from 'SharedComponents/personal_details/ducks';
import FormContainer from './form_container';

const mapStateToProps = (state) => ({
  personalDetailsFields: getPersonalDetailsFields(state),
  addressFields: getAddressFields(state),
  countryZones: getCountryZones(state),
  getDetailedPhoneNumber: getDetailedPhoneNumber(state),
  canUpdatePhoneNumber: getCanUpdatePhoneNumber(state),
  phoneNumberCountries: getMobileNumberCountries(state),
  detailedPhoneNumber: getDetailedPhoneNumber(state),
  loadingMobileCountries: getLoadingMobileCountries(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPersonalDetailsUpdated: compose(dispatch, setPersonalDetailsUpdated),
  fetchMobileNumberCountries: compose(dispatch, fetchMobileNumberCountries),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
